import jwtDecode from 'jwt-decode';

/**
 * Get legacy token from JWT token.
 * If issuer is instaply, the claim is legacyToken
 * If issuer is @https://instaply.eu.auth0.com/ then the claim is @https://instaply.com/sid
 * Returns null for unsupported issuers
 *
 * @param {String} jwtToken
 * @returns {String|null}
 */
export default (jwtToken) => {
  const decodedToken = jwtDecode(jwtToken);

  if (decodedToken.iss === 'instaply') {
    return decodedToken.legacyToken;
  }

  if (decodedToken.iss === 'https://instaply.eu.auth0.com/') {
    return decodedToken['https://instaply.com/sid'];
  }

  return null;
};
