import ReactDomServer from 'react-dom/server';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import isEmail from 'validator/lib/isEmail';
import moment from 'moment';
import {translate} from 'react-i18next';
import {useCallback, useEffect, useRef, useState} from 'react';
import debug from '../../../shared/lib/debug';
import ChangePasswordForm from '../../../shared/component/form/change-password-form-component';
import CountdownCircleTimer from '../../../shared/component/react-countdown-circle-timer/countdown-circle-timer-component';
import EmailField from '../../../shared/component/form/email-field-component';
import FormFieldsContainer from '../../../shared/component/form/fields-container-component';
import getErrorMessage from '../../../shared/lib/get-graphql-error-message';
import PasswordField from '../../../shared/component/form/password-field-component';
import PublicForm, {BottomLink} from '../../../shared/component/public/public-form-component';
import PublicPage from '../../../shared/component/public/public-page-component';
import StandardRaisedButton from '../../../shared/component/button/standard-raised-button-component';
import TermsOfServiceLink from '../../../shared/component/link/links-to-website';
import {red} from '../../../shared/style/colors';
import {fontSize, spacing} from '../../../shared/style/variables';
import {useAuth0} from '../../../shared/context/Auth0Context';

const MAX_LOGIN_ATTEMPTS = 5;

const SignInComponent = ({
  blockedUntil,
  errorContext,
  errors,
  isTinyScreen,
  language,
  mustUpdatePassword,
  signInImprovePassword,
  submitting,
  doContinueToTheApp,
  doImprovePasswordSubmit,
  doUIEndMount,
  doUIUpdateLanguage,
  onBlockingExpired,
  doSubmitSignIn,
  doFinalizeSsoSignIn,
  doInitiateSsoSignIn,
  i18n
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState({email: false, password: false});

  const keepSignedInRef = useRef(null);

  const auth0Client = useAuth0();
  const doSsoSignIn = () => {
    doInitiateSsoSignIn();
    auth0Client.loginWithRedirect({
      authorizationParams: {
        scope: 'openid profile email offline_access',
        prompt: 'login'
      }
    });
  };

  useEffect(() => {
    const handleAuth0Callback = async () => {
      if (
        window.location.search.includes('state=') &&
        (window.location.search.includes('code=') || window.location.search.includes('error='))
      ) {
        try {
          doInitiateSsoSignIn();
          await auth0Client.handleRedirectCallback();
          doFinalizeSsoSignIn();
        } catch (error) {
          debug('Error handling Auth0 callback:', error);
          // Clear the URL parameters to prevent retrying with invalid state
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      }
    };

    handleAuth0Callback();
  }, [auth0Client, window.location.search]);

  const doSubmitSignInForm = useCallback(() => {
    const isEmailValid = isEmail(email);
    const isPasswordValid = password !== '';

    setShowError({
      email: !isEmailValid,
      password: !isPasswordValid
    });

    doSubmitSignIn(
      email,
      keepSignedInRef.current?.checked,
      password,
      isEmailValid && isPasswordValid
    );
  }, [email, password, doSubmitSignIn]);

  // Handle form field changes
  const onFieldChange = (field, value) => {
    if (field === 'email') setEmail(value);
    if (field === 'password') setPassword(value);
  };

  // Handle field error status
  const updateFieldErrorStatus = () => {
    setShowError((prevState) => ({...prevState, email: false, password: false}));
  };

  if (mustUpdatePassword) {
    const title = i18n.t('signIn.improvePassword.title');

    return (
      <PublicPage
        dataTestId="improve-password"
        language={language}
        doUIEndMount={doUIEndMount}
        doUIUpdateLanguage={doUIUpdateLanguage}
        title={title}
      >
        <ChangePasswordForm
          description={i18n.t('signIn.improvePassword.description')}
          descriptionKeepHtml
          errors={signInImprovePassword.errors}
          passwordChanged={signInImprovePassword.passwordChanged}
          submitLabel={i18n.t('signIn.improvePassword.submitButton')}
          submitting={signInImprovePassword.submitting}
          doChangePasswordSubmit={doImprovePasswordSubmit}
          title={title}
          doContinueToTheApp={doContinueToTheApp}
        />
      </PublicPage>
    );
  }

  const title = i18n.t('signIn.title');
  const ssoTitle = i18n.t('signIn.sso.title');

  if (blockedUntil) {
    return (
      <PublicPage
        dataTestId="signin-blocked"
        language={language}
        title={title}
        doUIEndMount={doUIEndMount}
        doUIUpdateLanguage={doUIUpdateLanguage}
      >
        <PublicForm
          description={
            i18n.t('signIn.blocked.description1', {maxAttempts: MAX_LOGIN_ATTEMPTS}) +
            ReactDomServer.renderToStaticMarkup(
              <span style={{color: red}}>
                <br />
                {i18n.t('signIn.blocked.description2')}
              </span>
            )
          }
          descriptionKeepHtml
          title={i18n.t('signIn.blocked.title')}
        >
          <div
            data-test-id="countdown-clock"
            style={{
              marginTop: spacing.mlarge,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <span style={{marginBottom: spacing.medium}}>{i18n.t('signIn.blocked.wait')}</span>
            <CountdownCircleTimer
              duration={moment(blockedUntil).diff(errors[0]._serverDate, 'seconds')}
              fontSize={fontSize.xxxxlarge}
              onComplete={onBlockingExpired}
              size={100}
              strokeWidth={10}
            />
            <span style={{marginTop: spacing.medium}}>
              {i18n.t('signIn.blocked.contactYourAdministrator')}
            </span>
          </div>
        </PublicForm>
      </PublicPage>
    );
  }

  const stillWorking = submitting || mustUpdatePassword !== null;
  const remainingAttempts = errorContext ? errorContext.remainingAttempts : null;
  let globalErrorMessage = getErrorMessage(errors, 'signIn', i18n);
  if (remainingAttempts && remainingAttempts < MAX_LOGIN_ATTEMPTS - 1) {
    globalErrorMessage += i18n.t('signIn.remainingAttempts', {remainingAttempts});
  }

  return (
    <PublicPage
      dataTestId="signin"
      language={language}
      title={title}
      doUIEndMount={doUIEndMount}
      doUIUpdateLanguage={doUIUpdateLanguage}
    >
      <PublicForm
        doSubmit={doSubmitSignInForm}
        submitting={stillWorking}
        submittingLabel={i18n.t('form.submitting')}
        globalErrorMessage={globalErrorMessage}
        isTinyScreen={isTinyScreen}
        title={title}
        bottomLinks={[
          <BottomLink
            key="join"
            labelKey="signIn.wantToJoinYourTeam"
            link={
              <a data-test-id="join-team-link" href="/signup">
                {i18n.t('signIn.signUpClickHere')}
              </a>
            }
          />,
          <BottomLink
            key="terms"
            link={<TermsOfServiceLink>{i18n.t('signIn.termsAndConditions')}</TermsOfServiceLink>}
          />
        ]}
        buttons={
          stillWorking
            ? null
            : [
                <StandardRaisedButton
                  key="submit-sso"
                  type="button"
                  label={ssoTitle}
                  style={isTinyScreen ? {marginBottom: '10px'} : {width: 'auto'}}
                  onClick={doSsoSignIn}
                />,
                <StandardRaisedButton
                  key="submit"
                  label={title}
                  style={isTinyScreen ? null : {width: 'auto'}}
                />,
                <a
                  data-test-id="forgotten-password-link"
                  href="/forgotten-password"
                  key="forgottenPassword"
                  style={isTinyScreen ? {marginTop: spacing.xlarge} : null}
                >
                  {i18n.t('signIn.forgottenPassword')}
                </a>
              ]
        }
      >
        <FormFieldsContainer showRequired={false}>
          <EmailField
            autoFocus
            disabled={stillWorking}
            errorText={showError.email ? i18n.t('form.emailAddressRules') : null}
            onBlur={updateFieldErrorStatus}
            onChange={(e) => onFieldChange('email', e.target.value)}
            onFocus={updateFieldErrorStatus}
            value={email}
          />
          <PasswordField
            disabled={stillWorking}
            errorText={showError.password ? i18n.t('signIn.passwordRulesLegacy') : null}
            onBlur={updateFieldErrorStatus}
            onChange={(e) => onFieldChange('password', e.target.value)}
            onFocus={updateFieldErrorStatus}
            value={password}
          />
          <Checkbox
            data-test-id="keep-signed-in-checkbox"
            disabled={stillWorking}
            label={i18n.t('signIn.keepSignedIn')}
            ref={keepSignedInRef}
            style={{paddingTop: '20px'}}
          />
        </FormFieldsContainer>
      </PublicForm>
    </PublicPage>
  );
};

SignInComponent.propTypes = {
  blockedUntil: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isTinyScreen: PropTypes.bool,
  language: PropTypes.string,
  errorContext: PropTypes.shape({
    remainingAttempts: PropTypes.number // Assuming remainingAttempts is a number
  }),
  mustUpdatePassword: PropTypes.bool,
  signInImprovePassword: PropTypes.objectOf(PropTypes.any).isRequired,
  submitting: PropTypes.bool.isRequired,
  doContinueToTheApp: PropTypes.func.isRequired,
  doImprovePasswordSubmit: PropTypes.func.isRequired,
  doSubmitSignIn: PropTypes.func.isRequired,
  doFinalizeSsoSignIn: PropTypes.func.isRequired,
  doInitiateSsoSignIn: PropTypes.func.isRequired,
  doUIEndMount: PropTypes.func.isRequired,
  doUIUpdateLanguage: PropTypes.func.isRequired,
  onBlockingExpired: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired
  }).isRequired
};

export default translate()(SignInComponent);
