import {fromJS} from 'immutable';
import {createReducer} from 'redux-immutablejs';
import reduceReducers from 'reduce-reducers';
import errorsHandler, {resetErrors} from '../../../shared/reducer/lib/errors-handler';
import {
  SIGNIN_LOGIN_BLOCKING_EXPIRED,
  SIGNIN_LOGIN_SUBMIT,
  SIGNIN_LOGIN_SUBMIT_FAILURE,
  SIGNIN_LOGIN_SUBMIT_SUCCESS,
  SIGNIN_LOGIN_SSO_INITIATE,
  SIGNIN_LOGIN_SSO_FINALIZE
} from '../actions/signin-login-actions';

export const DEFAULT_STATE = {
  keepSignedIn: null,
  login: null,
  submitting: false,
  errorContext: null,
  errors: []
};

export default reduceReducers(
  createReducer(DEFAULT_STATE, {
    /**
     * Submit login.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNIN_LOGIN_SUBMIT]: (state) => {
      return resetErrors(state).set('submitting', true);
    },

    /**
     * Submit login success.
     *
     * @param {Object} state
     * @param {Boolean} keepSignedIn
     * @param {Object} login
     * @returns {Object} new state
     */
    [SIGNIN_LOGIN_SUBMIT_SUCCESS]: (state, {keepSignedIn, login}) => {
      return state
        .set('keepSignedIn', keepSignedIn)
        .set('login', fromJS(login))
        .set('submitting', false);
    },

    /**
     * Submit login failure.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNIN_LOGIN_SUBMIT_FAILURE]: (state) => {
      return state.set('submitting', false);
    },

    /**
     * Login blocking expired.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNIN_LOGIN_BLOCKING_EXPIRED]: (state) => {
      return resetErrors(state);
    },

    /**
     * Initiate SSO sign-in.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNIN_LOGIN_SSO_INITIATE]: (state) => {
      return resetErrors(state).set('submitting', true);
    },

    /**
     * Finalize SSO sign-in.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNIN_LOGIN_SSO_FINALIZE]: (state) => {
      return resetErrors(state).set('submitting', true);
    }
  }),
  errorsHandler
);
