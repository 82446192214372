import axios from 'axios';
import {createBrowserHistory} from 'history';
import {fromJS} from 'immutable';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales/en-gb';
import 'numeral/locales/es';
import 'numeral/locales/fr';
import 'numeral/locales/nl-nl';
import 'numeral/locales/pt-pt';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter, routerMiddleware} from 'react-router-redux';
import {applyMiddleware, compose, legacy_createStore as createStore} from 'redux';
import {autoRehydrate, createTransform, persistStore} from 'redux-persist-immutable';
import createEncryptor from 'redux-persist-transform-encrypt';
import ReduxSentryMiddleware from 'redux-sentry-middleware';
import {createAuth0Client} from '@auth0/auth0-spa-js';
import {createEpicMiddleware} from 'redux-observable';
import i18nInstance, {i18nBuilder} from './i18n';
import themeV0 from '../shared/style/theme-v0';
import setHtmlLanguage from '../shared/lib/window-helper';
import configLocaleCustomES from '../shared/lib/numeral-helper';
import topLevelRoutes from './top-level-routes';
import AppContainer from '../domain/app/container/app-container';
import ChangePasswordContainer from '../domain/change-password/container/change-password-container';
import ForgottenPasswordContainer from '../domain/forgotten-password/container/forgotten-password-container';
import SignInContainer from '../domain/signin/container/signin-container';
import SignUpContainer from '../domain/signup/container/signup-container';
import UnsubscribeContainer from '../domain/unsubscribe/container/unsubscribe-container';
import VerifyContainer from '../domain/verify/container/verify-container';
import '@mdi/font/css/materialdesignicons.css';
import {Auth0Provider} from '../shared/context/Auth0Context';

const attachSentryToAxios = () => {
  const __handleAxiosError = (error) => {
    Sentry.captureException(error);

    return Promise.reject(error);
  };

  axios.interceptors.request.use((config) => config, __handleAxiosError);

  axios.interceptors.response.use((response) => response, __handleAxiosError);
};

const googleAnalyticMiddleware = () => {
  return () => (next) => (action) => {
    if (action._googleAnalytics) {
      ga('send', 'event', action._googleAnalytics.eventCategory || 'app', action.type);
    }

    next(action);
  };
};

const initializeAuth0 = async () => {
  return createAuth0Client({
    domain: SSO_DOMAIN,
    clientId: SSO_CLIENT_ID,
    authorizationParams: {
      redirect_uri: `${window.location.origin}/signin`,
      audience: SSO_AUDIENCE,
      scope: 'openid profile email offline_access'
    },
    cacheLocation: 'localstorage',
    useRefreshTokens: true
  });
};

const threadsFormTransform = createTransform(
  // transform state coming from redux on its way to being serialized and stored
  (state) => state.filter((value, key) => key === 'threadsForm')
);

const organizationTransform = createTransform((state) =>
  state.filter((value, key) => key === 'organization')
);

/**
 * The app.
 *
 * @param {Function} domainStoreCreator
 * @param {Object} auth0Client - The Auth0 client instance
 */
const buildApp = (domainStoreCreator, auth0Client) => {
  const history = createBrowserHistory();
  const domainStore = domainStoreCreator(auth0Client);
  const epicMiddleware = createEpicMiddleware();

  // For some puzzling reason, without this test, few errors are logged
  if (!domainStore) {
    return;
  }

  let middlewares = [routerMiddleware(history), epicMiddleware];

  if (GOOGLE_ANALYTICS_ENABLED) {
    middlewares = [...middlewares, googleAnalyticMiddleware()];
  }

  if (SENTRY_ENABLED) {
    middlewares = [...middlewares, ReduxSentryMiddleware(Sentry)];
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    domainStore.rootReducer,
    fromJS({}),
    composeEnhancers(applyMiddleware(...middlewares), autoRehydrate())
  );

  epicMiddleware.run(domainStore.rootEpic);

  const encryptor = createEncryptor({
    secretKey: 'DO NOT CHANGE THIS VALUE'
  });

  persistStore(store, {
    keyPrefix: 'instaplyDesktop:',
    transforms: [threadsFormTransform, encryptor],
    whitelist: [
      'colleagueThread',
      'customerThreadAppPayment',
      'customerInvisibleThread',
      'customerVisibleThread',
      'legacyCustomerThreadAppPayment',
      'legacyCustomerVisibleThread',
      'legacyCustomerInvisibleThread'
    ]
  });

  persistStore(store, {
    keyPrefix: 'instaplyDesktop:',
    transforms: [organizationTransform, encryptor],
    whitelist: ['administration']
  });

  i18nBuilder(() => {
    const {language} = i18nInstance;

    setHtmlLanguage(language);

    numeral.register('locale', 'es-ES', configLocaleCustomES);
    // Use mapping to define numeral locale until fallback language is fixed in the lib
    numeral.locale(
      {
        en: 'en-gb',
        es: 'es-ES',
        'es-ES': 'es-ES',
        fr: 'fr',
        'fr-FR': 'fr',
        nl: 'nl-nl',
        pt: 'pt-pt'
      }[language]
    );

    moment.locale(language);

    // Create the App
    ReactDOM.render(
      <I18nextProvider i18n={i18nInstance}>
        <MuiThemeProvider muiTheme={themeV0}>
          <Provider store={store}>
            <Auth0Provider auth0Client={auth0Client}>
              <ConnectedRouter history={history}>
                <Switch>
                  <Route path="/app" component={AppContainer} />
                  <Route path="/change-password" component={ChangePasswordContainer} />
                  <Route path="/forgotten-password" component={ForgottenPasswordContainer} />
                  <Route path="/signin" component={SignInContainer} />
                  <Route path="/signup" component={SignUpContainer} />
                  <Route path="/unsubscribe" component={UnsubscribeContainer} />
                  <Route path="/verify" component={VerifyContainer} />
                </Switch>
              </ConnectedRouter>
            </Auth0Provider>
          </Provider>
        </MuiThemeProvider>
      </I18nextProvider>,
      document.getElementById('root')
    );
  });

  return {
    history,
    store
  };
};

/**
 * Let's start !
 */
export default async (rootPath) => {
  if (SENTRY_ENABLED) {
    Sentry.init({
      dsn: 'https://fe676e2f9b7b48868a14f05e31e59607@o31403.ingest.sentry.io/127610',
      environment: ENVIRONMENT,
      level: 'warning',
      release: REVISION
    });

    attachSentryToAxios();
  }

  const auth0Client = await initializeAuth0();
  buildApp(topLevelRoutes[rootPath], auth0Client);
};
