import {merge, of, from} from 'rxjs';
import {catchError, ignoreElements, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import moment from 'moment-timezone';
import currentPlatform from '../../../shared/lib/current-platform';
import {getAuthenticationHeader} from '../../../shared/store/middle-end-accessors';
import getLegacyToken from '../../../shared/epic/lib/get-legacy-token';
import getSignInNextUrl from '../lib/get-signin-next-url';
import handleErrors from '../../../shared/epic/lib/handle-errors';
import {setCookie} from '../../../shared/lib/cookie-helper';
import mobileAppsSupported from '../../../shared/data/mobile-apps';
import reportEmployeeUsage from '../../../shared/epic/lib/report-employee-usage';
import {
  SIGNIN_PASSWORD_COMPLIANT_FAILURE,
  SIGNIN_PASSWORD_COMPLIANT_SUCCESS
} from '../actions/signin-improve-actions';
import {
  SIGNIN_CHECK_NOT_SUPPORTED_MOBILE_FAILURE,
  SIGNIN_CHECK_NOT_SUPPORTED_MOBILE_SUCCESS,
  SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE,
  SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS,
  SIGNIN_LOGIN_SSO_FINALIZE,
  SIGNIN_LOGIN_SUBMIT,
  SIGNIN_LOGIN_SUBMIT_FAILURE,
  SIGNIN_LOGIN_SUBMIT_SUCCESS
} from '../actions/signin-login-actions';
import {COOKIE_SESSION_EXPIRES} from '../data/settings';
import debug from '../../../shared/lib/debug';

const ERROR_MAPPING = {
  40005: 'errorWrongCredentials',
  40068: 'errorOrganizationAccountExpired'
};

function doReportEmployeeUsage(graphql, authToken) {
  return reportEmployeeUsage(
    graphql,
    {
      eventType: 'Login',
      timezone: moment.tz.guess()
    },
    {
      headers: getAuthenticationHeader(authToken)
    },
    () => {
      return {
        type: SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS
      };
    },
    SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE
  );
}

/**
 * The epic itself.
 */
const SignInLoginEpic =
  ({graphql}, auth0Client) =>
  (action$, state$) => {
    const submitLogin = action$.pipe(
      ofType(SIGNIN_LOGIN_SUBMIT),
      mergeMap(({email, isFormValid, keepSignedIn, password}) => {
        if (!isFormValid) {
          return of({
            type: SIGNIN_LOGIN_SUBMIT_FAILURE,
            errors: []
          });
        }

        return graphql('login-mutation', {email, password}).pipe(
          map(({login}) => {
            return {
              type: SIGNIN_LOGIN_SUBMIT_SUCCESS,
              keepSignedIn,
              login
            };
          }),
          catchError((errors) => {
            return of({
              type: SIGNIN_LOGIN_SUBMIT_FAILURE,
              context: errors[0] ? errors[0].context : null,
              errors: handleErrors(errors, ERROR_MAPPING)
            });
          })
        );
      })
    );

    const checkNotSupportedMobile = action$.pipe(
      ofType(SIGNIN_LOGIN_SUBMIT_SUCCESS),
      map(() => {
        const supportedMobileApp = currentPlatform.isMobile
          ? mobileAppsSupported.find((app) => currentPlatform.osFamily === app.osFamily)
          : null;

        // Force the employee to use an app if its mobile support one
        if (supportedMobileApp) {
          window.location = supportedMobileApp.storeUrl;

          return {
            type: SIGNIN_CHECK_NOT_SUPPORTED_MOBILE_FAILURE
          };
        }

        return {
          type: SIGNIN_CHECK_NOT_SUPPORTED_MOBILE_SUCCESS
        };
      })
    );

    const checkPasswordCompliant = action$.pipe(
      ofType(SIGNIN_CHECK_NOT_SUPPORTED_MOBILE_SUCCESS),
      map(() => {
        if (!state$.value.getIn(['signInLogin', 'login', 'mustUpdatePassword'])) {
          return {
            type: SIGNIN_PASSWORD_COMPLIANT_SUCCESS
          };
        }

        return {
          type: SIGNIN_PASSWORD_COMPLIANT_FAILURE
        };
      })
    );

    const authenticateAndLogLoginActivity = action$.pipe(
      ofType(SIGNIN_PASSWORD_COMPLIANT_SUCCESS),
      mergeMap(() => {
        const keepSignedIn = state$.value.getIn(['signInLogin', 'keepSignedIn']);
        const jwtToken = state$.value.getIn(['signInLogin', 'login', 'accessToken']);
        const token = getLegacyToken(jwtToken);

        setCookie('token', token, keepSignedIn ? COOKIE_SESSION_EXPIRES : null);
        setCookie('jwtToken', jwtToken, keepSignedIn ? COOKIE_SESSION_EXPIRES : null);

        return doReportEmployeeUsage(graphql, jwtToken);
      })
    );

    const redirectToTheApp = action$.pipe(
      ofType(
        SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE, // activity log failure is not a reason to block login,
        SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS // but we must wait any server response to continue the login process
      ),
      map(() => {
        window.location = getSignInNextUrl();

        return null;
      }),
      ignoreElements()
    );

    const finalizeSsoSignIn = action$.pipe(
      ofType(SIGNIN_LOGIN_SSO_FINALIZE),
      mergeMap(() => {
        return from(auth0Client.getTokenSilently()).pipe(
          mergeMap((token) => {
            const legacyToken = getLegacyToken(token);
            setCookie('token', legacyToken);

            return doReportEmployeeUsage(graphql, token);
          }),
          catchError((error) => {
            debug('Error while finalizing SSO login', error);

            return of({
              type: SIGNIN_LOGIN_SUBMIT_FAILURE,
              errors: null
            });
          })
        );
      })
    );

    return merge(
      authenticateAndLogLoginActivity,
      checkNotSupportedMobile,
      checkPasswordCompliant,
      redirectToTheApp,
      submitLogin,
      finalizeSsoSignIn
    );
  };

export default SignInLoginEpic;
