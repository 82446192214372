import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';

const Auth0Context = createContext(null);

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({auth0Client, children}) => {
  return <Auth0Context.Provider value={auth0Client}>{children}</Auth0Context.Provider>;
};

Auth0Provider.propTypes = {
  auth0Client: PropTypes.shape({
    loginWithRedirect: PropTypes.func.isRequired
  }).isRequired,
  children: PropTypes.node.isRequired
};
